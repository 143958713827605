var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _vm.saveChannelInfo.loginLayout === "1"
        ? _c("login-l-r", { attrs: { saveChannelInfo: _vm.saveChannelInfo } })
        : _vm.saveChannelInfo.loginLayout === "2"
        ? _c("login-Card", { attrs: { saveChannelInfo: _vm.saveChannelInfo } })
        : _vm.saveChannelInfo.loginLayout !== "1" &&
          _vm.saveChannelInfo.loginLayout !== "2"
        ? _c("login-main", { attrs: { saveChannelInfo: _vm.saveChannelInfo } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }