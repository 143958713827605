<template>
  <!--找回密码-重置密码-->
  <div class="reset-pwd">
    <!-- <div class="step-wrap"> -->
      <!-- <el-steps :active="1" finish-status="success">
            <el-step title="找回密码" />
            <el-step title="重置密码" />
      </el-steps>-->
      <!-- <div class="step-item">
        <i class="circular">1</i>
        <span>找回密码</span>
      </div> -->
      <!-- <div class="step-item active">
        <i class="circular">2</i>
         <span>重置密码</span>
      </div> -->
    <!-- </div> -->
    <div class="resetpwd-wrap">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="repsw">
          <el-input type="password" placeholder="请输入新密码" v-model="form.repsw"/>
        </el-form-item>
        <el-form-item prop="vRepsw">
          <el-input type="password" placeholder="请再次输入新密码" v-model="form.vRepsw"/>
        </el-form-item>
        <el-form-item>
          <el-button class="sure-btn" @click="onResetPwd" v-loading="loading">
            确定
          </el-button>
        </el-form-item>
        <a class="login-txt" @click="login">登录</a>
      </el-form>
    </div>
  </div>
</template>

<script>
import { loginService } from "@/api";

export default {
  name: "ResetPwd",
  props: {
    forget: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      // 2.15新增的密码校验 输入的密码包含4个连续或重复的字符
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
      const reg1 = /(\w)*(\w)\2{3}(\w)*/g; // 判断4个连续一样的值
      const numArr = ["0123", "1234", "2345", "3456", "4567", "5678", "6789", "7890"];
      const letter = "abcdefghijklmnopqrstuvwxyz";
      const capital = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      function funarr(data) {
        const arr = [];
        for (let i = 0; i < 23; i++) {
          const str = data.substring(0, 4);
          arr.push(str);
          data = data.substring(1);
        }
        return arr;
      }
      const letterArr = funarr(letter);
      const capitalArr = funarr(capital);
      function checkpassw(passw) {
        numArr.forEach((value) => {
          if (passw.indexOf(value) > -1) {
            return false;
          }
          return value;
        });

        letterArr.forEach((value) => {
          if (passw.indexOf(value) > -1) {
            return false;
          }
          return value;
        });

        capitalArr.forEach((value) => {
          if (passw.indexOf(value) > -1) {
            return false;
          }
          return value;
        });
        return true;
      }
      // 2.15 检验是否包含汉子
      const reg2 = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (reg2.test(value)) {
        // 包含汉子
        callback(new Error("输入的密码不能包含中文"));
      }
      const reg3 = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]", "g");
      if (reg3.test(value)) {
        // 包含特殊字符
        callback(new Error("输入的密码不能包含特殊字符"));
      }
      if (value.indexOf(" ") > -1) {
        // 包含空格
        callback(new Error("输入的密码不能包含空格"));
      }
      if (value.length < 8) {
        callback(new Error("输入的密码不足8位"));
      }
      if (value.length > 12) {
        callback(new Error("输入的密码超过12位"));
      }
      if (!reg.test(value)) {
        callback(new Error("输入的密码必须包含字母(大写或小写)和数字的组合"));
      }
      if (reg1.test(value)) {
        callback(new Error("为了保证安全性，输入的密码不能包含4个重复的字符"));
      }
      if (!checkpassw(value)) {
        console.log(checkpassw(value));
        callback(new Error("为了保证安全性，输入的密码不能包含4个连续的字符"));
        return;
      }

      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.vRepsw !== "") {
          this.$refs.form.validateField("vRepsw");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.repsw) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        repsw: "",
        vRepsw: "",
        mobileNo: sessionStorage.getItem("userPhone") || "",
        sendMsgMobileNo: sessionStorage.getItem("userPhone") || "",
        validateCode: "",
        type: 2
      },
      rules: {
        repsw: [
          {
            required: true,
            message: "请输入新密码",
            trigger: true
          },
          { validator: validatePass, trigger: "blur" }
        ],
        vRepsw: [
          {
            required: true,
            message: "请再次输入新密码",
            trigger: true
          },
          { validator: validatePass2, trigger: "blur" }
        ]
      },
      loading: false
    };
  },
  watch: {
    forget: {
      handler() {
        this.setDatas();
      },
      deep: true
    }
  },
  methods: {
    setDatas() {
      this.form = Object.assign(this.form, this.forget);
    },
    onResetPwd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.resetPwd();
        }
      });
    },
    async resetPwd() {
      try {
        this.loading = true;
        console.log(loginService);
        const res = await loginService.forgetPassword(this.form);
        console.log(res);
        localStorage.removeItem("showForget");
        this.$emit("login");
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    login() {
      this.$emit("login");
    }
  },
  beforeMount() {
    this.setDatas();
  }
};
</script>

<style lang="scss" scoped>
.step-wrap {
  position: relative;
  display: table;
  margin: 40px auto;
  font-size: 12px;
  .circular {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #a2c9ef;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    margin-right: 10px;
  }
  .step-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 100px;
    &:last-child {
      margin-right: 0;
    }
  }
  &:after {
    position: absolute;
    left: 90px;
    right: 90px;
    top: 10px;
    content: "";
    height: 2px;
    background: #4694df;
  }
}
.resetpwd-wrap {
  display: table;
  margin: 20px auto 0;
  & /deep/ .el-input__inner,
  & /deep/ .el-input {
    height: 40px;
    width: 460px;
  }
  .pin-inp {
    width: 354px;
    margin-right: 10px;
  }
  .getpin-btn {
    background: #4694df;
    color: #fff;
    width: 100px;
  }
  .login-txt {
    text-align: right;
    font-size: 12px;
    color: #4694df;
    width: 460px;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }
}
.sure-btn {
  text-align: center;
  height: 45px;
  width: 100%;
  background: #4694df;
  color: #fff;
  letter-spacing: 5px;
}
.active {
  .circular {
    background: #4694df;
  }
  span {
    color: #4694df;
  }
}
</style>
