var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reset-pwd" }, [
    _c(
      "div",
      { staticClass: "resetpwd-wrap" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "repsw" } },
              [
                _c("el-input", {
                  attrs: { type: "password", placeholder: "请输入新密码" },
                  model: {
                    value: _vm.form.repsw,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "repsw", $$v)
                    },
                    expression: "form.repsw",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "vRepsw" } },
              [
                _c("el-input", {
                  attrs: { type: "password", placeholder: "请再次输入新密码" },
                  model: {
                    value: _vm.form.vRepsw,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vRepsw", $$v)
                    },
                    expression: "form.vRepsw",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "sure-btn",
                    on: { click: _vm.onResetPwd },
                  },
                  [_vm._v(" 确定 ")]
                ),
              ],
              1
            ),
            _c("a", { staticClass: "login-txt", on: { click: _vm.login } }, [
              _vm._v("登录"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }