<!--
 * @Description:
 * @Author: 周晴龙
 * @LastEditors: wuqi
 * @Date: 2019-07-19 15:06:54
 * @LastEditTime: 2021-06-18 16:53:44
 -->
 <template>
  <div class="main">
    <login-l-r v-if = "saveChannelInfo.loginLayout === '1' " :saveChannelInfo="saveChannelInfo"></login-l-r>
    <login-Card v-else-if = "saveChannelInfo.loginLayout === '2' " :saveChannelInfo="saveChannelInfo"></login-Card>
    <login-main v-else-if = "saveChannelInfo.loginLayout !== '1' && saveChannelInfo.loginLayout !== '2' " :saveChannelInfo="saveChannelInfo"></login-main>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { loginService } from "@/api";
import LoginMain from "./main.vue";
import LoginCard from "../loginCard/index.vue";
import LoginLR from "../loginLeftRight/index.vue";

export default {
  name: "Login",
  components: {
    LoginMain,
    LoginCard,
    LoginLR
  },
  data() {
    return {
      viewStatus: "login",
      test: "",
      forgetVal: {},
      saveChannelInfo: {}
    };
  },
  created() {
    this.clCorpQuery();// TODO 网关接口需要校验登录状态 暂时不调用
  },
  computed: {
    ...mapState(["componyMeta"]),
    showLogin() {
      return this.viewStatus === "login";
    },
    showForget() {
      return this.viewStatus === "forget";
    },
    showReset() {
      return this.viewStatus === "reset";
    }
  },
  methods: {
    ...mapMutations(["saveComponyMeta"]),
    backVersion() {
      const urlMap = {
        "192.168.1.52": "https://en.enfry.com/",
        "en1.enfry.com": "https://en.enfry.com/",
        "192.168.1.52:8024": "https://en.enfry.cn/",
        "192.168.1.52:8023": "https://dev.enfry.cn/"
      };
      window.location.href = urlMap[window.location.host] || "https://en.enfry.com/";
    },
    updateShowView(data) {
      this.viewStatus = data;
    },
    setMetaInfo(meta) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      document.title = meta.mainSlogan || document.title;
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = meta.channelLogoUrl;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    async clCorpQuery() {
      const addr = decodeURI(window.location.origin);
      const params = {
        mappingAddress: addr,
        channelType: "PC",
        channelcode: "PC",
        appSessionId: Math.random()
      };
      sessionStorage.setItem("url", addr);
      const channelInfo = await loginService.clCorpQuery(params);
      this.saveChannelInfo = channelInfo;
      console.log(this.saveChannelInfo, "this.saveChannelInfo");
      if (channelInfo.id !== "1000000000001") {
        if (channelInfo.channelIconLogo) {
          channelInfo.channelIconLogoUrl = channelInfo.channelLogoUrl.replace(
            channelInfo.channelLogo,
            channelInfo.channelIconLogo
          );
        } else {
          channelInfo.channelIconLogoUrl = channelInfo.channelLogoUrl;
        }

        this.saveComponyMeta(channelInfo);
        this.setMetaInfo(channelInfo);
      } else {
        this.saveComponyMeta({});
      }
    },
    async clChannelSetQuery() {
      const channelLogo = await loginService.clChannelSetQuery();
      console.log(channelLogo, "channelLogochannelLogo");
    },
    showResetView(val) {
      this.forgetVal = val;
      this.viewStatus = "reset";
    },
    showLoginView() {
      this.viewStatus = "login";
    }
  }

};
</script>
