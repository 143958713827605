<!--
 * @Description:
 * @Author: 周晴龙
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-19 15:06:54
 * @LastEditTime: 2024-02-29 11:53:14
 -->
<template>
  <div class="login-lr">
    <div class="login-warp container-fluid">
      <div class="row display-sb">
        <div class="login-side col-md-3" :style="{ '--bg-image': 'url(' + saveChannelInfo. loginBackgroundUrl + ')' }">
          <div class="login-side-content">
            <img class="login-side-logo" :src="this.saveChannelInfo.loginLogoUrl" alt="" />
            <p class="login-side-font title" :style="{'fontSize':saveChannelInfo.msloganFont,
            'color':saveChannelInfo.msloganColor,'fontWigth':saveChannelInfo.msloganBold,
            'text-align':saveChannelInfo.msloganAlign === '0'
             ? 'left':saveChannelInfo.msloganAlign === '1'?'center':'right'}">
              {{ saveChannelInfo.mainSlogan }}
            </p>
            <p class="login-side-font fontStyle" :style="{'fontSize':saveChannelInfo.fsloganFont,
            'color':saveChannelInfo.fsloganColor,'fontWigth':saveChannelInfo.fsloganBold === '1' ? 600 : 400,
            'text-align':saveChannelInfo.fsloganAlign === '0' ? 'left':saveChannelInfo.fsloganAlign === '1'?'center':'right'}">
              {{ saveChannelInfo.deputySlogan }}
            </p>
          </div>
        </div>
        <div class="login-content col-md-9">
          <div class="login-navlis">
            <login-main v-if="showLogin" @updateView="updateShowView" />
            <div v-else class="get-pwdmain">
              <forget-pwd v-if="showForget" @reset-password="showResetView" @login="showLoginView" />
            </div>
          </div>
          <div class="login-footer">
            <a href="">河洛</a><span>|</span> <a href=""> 服务协议 </a><span>|</span>
            <a href=""> 权利声明 </a><span>|</span> <a href=""> 版本更新 </a><span>|</span>
            <a href=""> 帮助中心 </a><span>|</span> <a href=""> 问题反馈 </a><span class="login-copyright"> @2024 Future data Technology Co., LTD  rights reserved </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { loginService } from "@/api";
import { getFileUrl } from "@/tools/getFileUrl";
import LoginMain from "./login/LoginMain.vue";
import ForgetPwd from "./forgetPwd/ForgetPwd.vue";

export default {
  name: "Login",
  props: {
          saveChannelInfo: {
            type: Object,
            default: () => {}
          }
  },
  components: {
    ForgetPwd,
    LoginMain
  },
  data() {
    return {
      viewStatus: "login",
      test: "",
      forgetVal: {}
    };
  },
  created() {

  },
  computed: {
    ...mapState(["componyMeta"]),
    showLogin() {
      return this.viewStatus === "login";
    },
    showForget() {
      return this.viewStatus === "forget";
    },
    showReset() {
      return this.viewStatus === "reset";
    }
  },
  methods: {
    getFileUrl(url) {
      return getFileUrl(url, "000", "");
    },
    ...mapMutations(["saveComponyMeta"]),
    // showForgetView() {
    //   this.viewStatus = "forget";
    // },
    backVersion() {
      const urlMap = {
        "192.168.1.52": "https://en.enfry.com/",
        "en1.enfry.com": "https://en.enfry.com/",
        "192.168.1.52:8024": "https://en.enfry.cn/",
        "192.168.1.52:8023": "https://dev.enfry.cn/"
      };
      window.location.href = urlMap[window.location.host] || "https://en.enfry.com/";
    },
    updateShowView(data) {
      this.viewStatus = data;
    },
    setMetaInfo(meta) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      document.title = meta.mainSlogan || document.title;
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = meta.channelLogoUrl;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    async clCorpQuery() {
      const addr = decodeURI(window.location.origin);
      // const addr = decodeURI("http://localhost:8081/user/login");
      const params = {
        mappingAddress: addr,
        channelType: "PC",
        channelcode: "PC",
        appSessionId: Math.random()
      };
      sessionStorage.setItem("url", addr);

      const channelLogo = await loginService.clCorpQuery(params);
      console.log(channelLogo, "channelLogochannelLogochannelLogo");
      if (channelLogo.id !== "1000000000001") {
        if (channelLogo.channelIconLogo) {
          channelLogo.channelIconLogoUrl = channelLogo.channelLogoUrl.replace(
            channelLogo.channelLogo,
            channelLogo.channelIconLogo
          );
        } else {
          channelLogo.channelIconLogoUrl = channelLogo.channelLogoUrl;
        }

        this.saveComponyMeta(channelLogo);
        this.setMetaInfo(channelLogo);
      } else {
        this.saveComponyMeta({});
      }
    },
    async clChannelSetQuery() {
      const channelLogo = await loginService.clChannelSetQuery();
      console.log(channelLogo, "channelLogochannelLogo");
    },
    showResetView(val) {
      this.forgetVal = val;
      this.viewStatus = "reset";
    },
    showLoginView() {
      this.viewStatus = "login";
    }
  }
};
</script>

<style lang="scss" scoped>
.login-side-logo{
  height:65px;
}
.login-lr {
  height: 100%;

  .login-warp {
    background: #404352;
    width: 100%;
    height: 100%;

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .row {
      height: 100%;
    }

    .display-sb {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .login-side {
        position: relative;
        width: 340px;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        .login-side-content {
          width: 100%;
          height: 340px;
          z-index: 99;
          text-align: center;
          margin-bottom: 140px;
        }

        .login-side-font {
          font-size: 16px;
          color: #fff;
        }

        .title {
          font-size: 30px;
          font-family: cursive;
          font-weight: 500;
        }

        .fontStyle {
          margin-top: 6px;
          font-style: italic;
        }
      }

      img,
      p {
        margin: 0;
        z-index: 9;
      }

    }

    .login-side::after {
      content: "";
      position: absolute;
      top: 0;
      left: -25px;
      width: calc(100% + 25px);
      height: 100%;
      // background: url(../../assets/images/login-lr-bgc.jpeg);
      background: var(--bg-image);
      background-size: cover;
      background-position: center;
      /* 设置动画 */
      animation-name: slide;
      animation-duration: 5s;
      animation-timing-function: ease-out;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    @keyframes slide {
      0% {
        transform: translateX(0);
      }

      50% {
        transform: translateX(20px);
      }

      100% {
        transform: translateX(0);
      }
    }

    .login-content {
      width: 75%;
      height: 100%;
      position: relative;

      .login-navlis {
        width: 456px;
        position: absolute;
        left: 50%;
        top: 45%;
        margin-top: -200px;
        margin-left: -230px;
      }

      .login-footer {
        text-align: center;
        color: #8894a4;
        position: absolute;
        bottom: 10px;
        width: 75%;
        height: 30px;

        a {
          color: #8894a4;
          margin: 0 5px;
          font-size: 12px;
        }

        .login-copyright {
          font-size: 12px;
        }
      }
    }

    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
</style>
