<!--
 * @Author: zhulin
 * @Date: 2021-05-19 10:30:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-14 13:29:22
 * @Description: 扫码登录
 * @FilePath: \user\src\views\login\scanLogin\ScanLogin.vue
-->
<template>
  <div class="scan-login">
    <p class="title">扫码登录</p>
    <div class="code">
      <div class="codeimg" ref="codeimg" v-if="!loginState"></div>
    </div>
    <div class="info-txt">
      <span>{{ loginMsg }}</span>
    </div>
    <!-- <div class="recode" v-if="!loginState" @click="freshCode"><en-icon name="iconhuifu1" size="16px" style="color:#3cb5fc;"></en-icon><span>刷新</span></div> -->
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { loginService } from "@/api";
// import { mapGetters } from "vuex";

export default {
  name: "ScanLogin",
  data() {
    return {
      logincount: 0,
      olduid: "",
      uid: "",
      loginInterval: -1,
      loginState: "",
      loginMsg: "",
      clickFlag: 1,
      qrcode: ""
    };
  },
  watch: {},
  mounted() {
    this.qrcode = this.creatQrCode();
    this.scanLogin();
  },
  methods: {
    /**
     * 扫码登录
     */
    scanLogin() {
      const uid = this.uuid();
      this.qrcode.makeCode(uid);
      window.clearInterval(this.loginInterval);
      const that = this;
      that.logincount = 0;
      // 定时请求
      function getTotelNumber() {
        if (that.logincount > 15) {
          window.clearInterval(that.loginInterval);
          that.loginInterval = -1;
          that.olduid = uid;
          that.scanLogin();
          return;
        }
        const pa = {
          uuId: uid,
          oldUuid: that.olduid,
          appSessionId: Math.random(),
          channelType: "PC",
          channelcode: "PC"
        };
        console.log(that.logincount, pa);
        that.scanCode(pa);
        that.logincount++;
      }
      this.loginInterval = setInterval(getTotelNumber, 2000);
    },
    async scanCode(pa) {
      const data = await loginService.getLoginQrCode(pa);
      console.log(data);
      if (!data) return;
      // data = typeof data === "string" ? JSON.parse(data) : data;
      if (data) {
        if (data.type === "000") {
          // 扫描成功
          this.loginState = data.type;
          this.loginMsg = "扫码成功，请在手机上点击确认";
        } else if (data.type === "001") {
          // 登录成功
          window.clearInterval(this.loginInterval);
          this.loginInterval = -1;
          this.loginState = data.type;
          // 登录
          this.$emit("goLogin", {
            name: data.name,
            password: data.passwd,
            channelcode: "QRCODE",
            channelType: "PC"
          });
        } else {
          // 重新扫描
        }
      } else {
        window.clearInterval(this.loginInterval);
        this.loginInterval = -1;
      }
    },
    /**
     * @description: 刷新二维码
     * @param {*}
     * @return {*}
     */
    freshCode() {
      if (this.clickFlag) {
        this.clickFlag = 0;
        this.scanLogin();
        setTimeout(() => {
          this.clickFlag = 1;
        }, 2000);
      }
    },
    creatQrCode() {
      return new QRCode(this.$refs.codeimg, {
        width: 230,
        height: 230
      });
    },
    /* eslint-disable */
    uuid() {
      return (
        "https://www.enfry.com/s/login/" +
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
          const r = (Math.random() * 16) | 0;
          const v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        })
      );
    },
    changeView() {
      this.$emit("changeView");
    }
  },
  beforeDestroy() {
    //页面关闭时清除定时器
    clearInterval(this.loginInterval);
  }
};
</script>

<style lang="scss" scoped>
.scan-login {


  background: #fff;
  border-radius: 20px;

  text-align: center;
  font-size: 12px;
  line-height: 24px;
  z-index: 99999;
  .en-icon {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }
  .title {
    margin-top: 30px;
    font-size: 24px;
    padding: 30px 0 30px 0;
    margin: 0;
    color: #333;
  }
  .info-txt {
    margin: 28px 0 10px;
    color: #636c78;
    font-size: 16px;
  }
  .codeimg {
    width: 260px;
    height: 260px;
    padding: 15px 0 0 15px;
    box-sizing: border-box;
    border: 1px solid #ededed;
    margin: 0 auto 10px;
    background: #fff;
    border-radius: 10px;
     display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .recode {
    line-height: 18px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 12px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px;
    }
  }
}
</style>
