var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-card" }, [
    _c("div", { staticClass: "tar" }, [
      _c("div", { staticClass: "title" }, [
        _vm.showView === "scan"
          ? _c("div", { staticClass: "poab" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/psw.png"), alt: "" },
                on: {
                  click: function ($event) {
                    _vm.showView = "psw"
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm.showView === "psw"
          ? _c("div", { staticClass: "poab" }, [
              _c("img", {
                attrs: { src: require("@/assets/images/scan.png"), alt: "" },
                on: {
                  click: function ($event) {
                    _vm.showView = "scan"
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm.showView === "psw"
          ? _c("div", { staticClass: "myPoab" }, [
              _vm._v(" 扫码登录 安全快捷 "),
            ])
          : _vm._e(),
      ]),
      _vm.showView === "psw"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "tabs-login-card" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.showView,
                      callback: function ($$v) {
                        _vm.showView = $$v
                      },
                      expression: "showView",
                    },
                  },
                  [
                    _c("el-tab-pane", {
                      attrs: { label: "账号登陆", name: "psw" },
                    }),
                    _c("el-tab-pane", {
                      attrs: { label: "扫码登录", name: "scan" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content" },
              [
                !_vm.appSessionId
                  ? _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        staticClass: "login-form",
                        attrs: {
                          model: _vm.ruleForm,
                          rules: _vm.rules,
                          "show-message": false,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "col", attrs: { prop: "name" } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "mt27",
                                attrs: { placeholder: "请输入账号/手机号" },
                                model: {
                                  value: _vm.ruleForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "name", $$v)
                                  },
                                  expression: "ruleForm.name",
                                },
                              },
                              [
                                _c(
                                  "template",
                                  {
                                    staticClass: "input-label",
                                    slot: "prepend",
                                  },
                                  [_vm._v("账号/手机号")]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "col", attrs: { prop: "password" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  type: "password",
                                  placeholder: "请输入密码",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.onLogin("ruleForm")
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "password", $$v)
                                  },
                                  expression: "ruleForm.password",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("密码"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "login mt27" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.requestLoading,
                      size: "small",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onLogin("ruleForm")
                      },
                    },
                  },
                  [_vm._v("登录")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tac mt27" },
              [
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  },
                  [
                    _vm._v("我已阅读并同意 "),
                    _c(
                      "a",
                      { staticClass: "color-blue", attrs: { href: "" } },
                      [_vm._v("《隐私政策》")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showView === "scan"
        ? _c(
            "div",
            [
              _c("scan-login", {
                on: {
                  changeView: function ($event) {
                    _vm.showView = "psw"
                  },
                  goLogin: _vm.scanLogin,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }