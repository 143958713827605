<!--
 * @Description:
 * @Author: 周晴龙
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-19 15:06:54
 * @LastEditTime: 2024-02-29 11:47:02
 -->
 <template>
  <div class="main">
    <img :src="this.saveChannelInfo.loginBackgroundUrl" class="main-img" />
    <div class="logo">
      <img :src="this.saveChannelInfo.loginLogoUrl" />
    </div>
    <!--登陆主界面-->
    <login-main
      v-if="showLogin"
      @updateView="updateShowView"
      :saveChannelInfo="saveChannelInfo"
    />
    <div v-else class="get-pwdmain">
      <div class="title">
        <span>重置密码</span>
      </div>
      <!--忘记密码-->
      <forget-pwd
        v-if="showForget"
        @reset-password="showResetView"
        @login="showLoginView"
      />
      <!--找回密码-->
      <reset-pwd v-if="showReset" :forget="forgetVal" @login="showLoginView" />
    </div>
    <div class="footer">
      <p>{{ saveChannelInfo.channelName }}</p>
      <p>@2024 Future data Technology Co., LTD rights reserved</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { loginService } from "@/api";
import { getFileUrl } from "@/tools/getFileUrl";
import LoginMain from "./login/LoginMain.vue";
import ForgetPwd from "./forgetPwd/ForgetPwd.vue";
import ResetPwd from "./resetPwd/ResetPwd.vue";

export default {
  name: "Login",
  props: {
    saveChannelInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    LoginMain,
    ForgetPwd,
    ResetPwd
  },
  data() {
    return {
      viewStatus: "login",
      test: "",
      forgetVal: {}
    };
  },
  created() {
    console.log(this.saveChannelInfo);
    this.saveComponyMeta({});
    if (localStorage.getItem("showForget") === "1") {
      this.viewStatus = "reset";
      // localStorage.removeItem("reset");
    }
  },
  computed: {
    ...mapState(["componyMeta"]),
    showLogin() {
      return this.viewStatus === "login";
    },
    showForget() {
      return this.viewStatus === "forget";
    },
    showReset() {
      return this.viewStatus === "reset";
    }
  },
  methods: {
    getFileUrl(url) {
      return getFileUrl(url, "000", "");
    },
    ...mapMutations(["saveComponyMeta"]),
    // showForgetView() {
    //   this.viewStatus = "forget";
    // },
    backVersion() {
      // const urlMap = {
      //   "192.168.1.52": "https://en.enfry.com/",
      //   "en1.enfry.com": "https://en.enfry.com/",
      //   "192.168.1.52:8024": "https://en.enfry.cn/",
      //   "192.168.1.52:8023": "https://dev.enfry.cn/"
      // };
      window.location.href = "https://master.softheluo.com/";
    },
    updateShowView(data) {
      this.viewStatus = data;
    },
    setMetaInfo(meta) {
      const link = document.querySelector("link[rel*='icon']")
        || document.createElement("link");
      document.title = meta.mainSlogan || document.title;
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = meta.channelLogoUrl;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    async clCorpQuery() {
      const mappingAddress = decodeURI(window.location.origin);
      const params = { mappingAddress };
      sessionStorage.setItem("url", mappingAddress);
      const channelLogo = await loginService.clCorpQuery(params);
      if (channelLogo.id !== "1000000000001") {
        if (channelLogo.channelIconLogo) {
          channelLogo.channelIconLogoUrl = channelLogo.channelLogoUrl.replace(
            channelLogo.channelLogo,
            channelLogo.channelIconLogo
          );
        } else {
          channelLogo.channelIconLogoUrl = channelLogo.channelLogoUrl;
        }

        this.saveComponyMeta(channelLogo);
        this.setMetaInfo(channelLogo);
      } else {
        this.saveComponyMeta({});
      }
    },
    showResetView(val) {
      this.forgetVal = val;
      this.viewStatus = "reset";
    },
    showLoginView() {
      this.viewStatus = "login";
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  min-width: 1000px;
  overflow: hidden;
  height: 100%;
  .back-version-btn {
    position: absolute;
    z-index: 10;
    top: 40px;
    right: 48px;
    display: inline-block;
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}
.main-img {
  position: absolute;
  top: 0;
  left: -10%px;
  right: -10%px;
  bottom: 0;
  width: 110%;
  height: 100%;
  animation: mymove 5s infinite linear;
  -webkit-animation: mymove 5s infinite linear;
  animation-direction: alternate;
  z-index: -1;
}
@keyframes mymove {
  0% {
    left: -40px;
  }
  100% {
    left: 0px;
  }
}

@-webkit-keyframes mymove {
  0% {
    left: -40px;
  }
  100% {
    left: 0px;
  }
}
.logo {
  text-align: left;
  img {
    height: 65px;
    margin: 45px;
  }
}
.footer {
  position: absolute;
  bottom: 50px;
  height: 50px;
  font-size: 12px;
  text-align: center;
  width: 100%;
  p {
    text-align: center;
    color: #fff;
    line-height: 10px;
    opacity: 0.5;
    letter-spacing: 1px;
  }
}
.get-pwdmain {
  background: #fff;
  max-width: 80%;
  min-width: 900px;
  border-radius: 5px;
  padding: 60px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    text-align: center;
    font-size: 14px;
  }
}
</style>
