<template>
  <div class="wscn-http404-container">
    <en-result
      type="NotFound"
      title="错误页面"
      sub-title="您访问的页面不存在"
      class="not-found"
    >
    </en-result>
    <router-link
      to="/"
      class="bullshit__return-home"
    >
      返回首页
    </router-link>
  </div>
</template>

<script>

export default {
  name: "Page404",
  data() {
    return {
    };
  }
};
</script>

<style lang="scss" scoped>
.wscn-http404-container {
  display: flex;
  justify-content: center; //子元素水平居中
  align-items: center;
  flex-direction: column;
}
</style>
