var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("img", {
        staticClass: "main-img",
        attrs: { src: this.saveChannelInfo.loginBackgroundUrl },
      }),
      _c("div", { staticClass: "logo" }, [
        _c("img", { attrs: { src: this.saveChannelInfo.loginLogoUrl } }),
      ]),
      _c("login-main", { attrs: { saveChannelInfo: _vm.saveChannelInfo } }),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("span", [_vm._v("服务协议")]),
          _c("el-divider", {
            staticClass: "color-b",
            attrs: { direction: "vertical" },
          }),
          _c("span", [_vm._v("权利声明")]),
          _c("el-divider", {
            staticClass: "color-b",
            attrs: { direction: "vertical" },
          }),
          _c("span", [_vm._v("版本更新")]),
          _c("el-divider", {
            staticClass: "color-b",
            attrs: { direction: "vertical" },
          }),
          _c("span", [_vm._v("帮助中心")]),
          _c("el-divider", {
            staticClass: "color-b",
            attrs: { direction: "vertical" },
          }),
          _c("span", [
            _vm._v(
              "问题反馈 @ 2024 Future data Technology Co., LTD rights reserved"
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }