var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-main" },
    [
      _vm.showView === "psw"
        ? _c("div", { staticClass: "txt" }, [
            _vm.saveChannelInfo.mainSlogan
              ? _c(
                  "div",
                  {
                    staticClass: "main-txt",
                    style: {
                      fontSize: _vm.saveChannelInfo.msloganFont,
                      color: _vm.saveChannelInfo.msloganColor,
                      fontWigth: _vm.saveChannelInfo.msloganBold,
                      "text-align":
                        _vm.saveChannelInfo.msloganAlign === "0"
                          ? "left"
                          : _vm.saveChannelInfo.msloganAlign === "1"
                          ? "center"
                          : "right",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.saveChannelInfo.mainSlogan))]
                )
              : _vm._e(),
            _vm.saveChannelInfo.deputySlogan
              ? _c(
                  "div",
                  {
                    staticClass: "sec-txt",
                    style: {
                      fontSize: _vm.saveChannelInfo.fsloganFont,
                      color: _vm.saveChannelInfo.fsloganColor,
                      fontWigth:
                        _vm.saveChannelInfo.fsloganBold === "1" ? 600 : 400,
                      "text-align":
                        _vm.saveChannelInfo.fsloganAlign === "0"
                          ? "left"
                          : _vm.saveChannelInfo.fsloganAlign === "1"
                          ? "center"
                          : "right",
                    },
                  },
                  [_vm._v(_vm._s(_vm.saveChannelInfo.deputySlogan))]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.appSessionId
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "show-message": false,
              },
            },
            [
              _vm.showView === "psw"
                ? _c(
                    "div",
                    { staticClass: "line" },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "col", attrs: { prop: "name" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "账号/手机号",
                                autocomplete: "",
                                name: "name",
                              },
                              model: {
                                value: _vm.ruleForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "name", $$v)
                                },
                                expression: "ruleForm.name",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "prefix" },
                                [
                                  _c("en-icon", {
                                    staticStyle: { "margin-top": "16px" },
                                    attrs: {
                                      color: "#3e90fe",
                                      size: "small",
                                      name: "iconzhanghao-denglu",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "col", attrs: { prop: "password" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { type: "password", placeholder: "密码" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.onLogin("ruleForm")
                                },
                              },
                              model: {
                                value: _vm.ruleForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "password", $$v)
                                },
                                expression: "ruleForm.password",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "prefix" },
                                [
                                  _c("en-icon", {
                                    staticStyle: { "margin-top": "16px" },
                                    attrs: {
                                      color: "#3e90fe",
                                      size: "small",
                                      name: "iconmima-denglu",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "col" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "login-btn",
                              attrs: {
                                loading: _vm.requestLoading,
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onLogin("ruleForm")
                                },
                              },
                            },
                            [_vm._v(" 登录 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showView === "scan"
                ? _c("scan-login", {
                    on: {
                      changeView: function ($event) {
                        _vm.showView = "psw"
                      },
                      goLogin: _vm.scanLogin,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "line" },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "col", attrs: { prop: "pin" } },
                    [
                      _c("div", { staticClass: "pin-box" }),
                      _c(
                        "el-input",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.validateVisible && _vm.showView === "psw",
                              expression:
                                "validateVisible && showView === 'psw'",
                            },
                          ],
                          attrs: { placeholder: "验证码" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.onLogin("ruleForm")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.validateCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "validateCode", $$v)
                            },
                            expression: "ruleForm.validateCode",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "prefix" },
                            [
                              _c("en-icon", {
                                staticStyle: { "margin-top": "18px" },
                                attrs: {
                                  color: "#3e90fe",
                                  size: "small",
                                  name: "iconyanzhengma",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { staticClass: "col" }, [
                    _c("div", { staticClass: "pin-box" }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.validateVisible && _vm.showView === "psw",
                          expression: "validateVisible && showView === 'psw'",
                        },
                      ],
                      attrs: { height: "54", src: _vm.validateImage },
                      on: { click: _vm.getValidateCode },
                    }),
                  ]),
                  _vm.showView === "psw"
                    ? _c(
                        "el-form-item",
                        { staticClass: "col col-nav" },
                        [
                          _vm.showView === "scan"
                            ? _c(
                                "span",
                                {
                                  staticClass: "txt-smlogin",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeView("psw")
                                    },
                                  },
                                },
                                [_vm._v("帐号登录")]
                              )
                            : _vm._e(),
                          _vm.showView === "psw"
                            ? _c(
                                "a",
                                {
                                  staticClass: "txt-smlogin",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeView("scan")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("扫码登录")))]
                              )
                            : _vm._e(),
                          _c("en-icon", {
                            staticStyle: {
                              color: "#3cb5fc",
                              "margin-top": "-4px",
                            },
                            attrs: {
                              name: "qiehuanfangshi-denglu",
                              size: "16px",
                            },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "txt-forget",
                              on: {
                                click: function ($event) {
                                  return _vm.updateData("forget")
                                },
                              },
                            },
                            [_vm._v("忘记密码？")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }