var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-lr" }, [
    _c("div", { staticClass: "login-warp container-fluid" }, [
      _c("div", { staticClass: "row display-sb" }, [
        _c(
          "div",
          {
            staticClass: "login-side col-md-3",
            style: {
              "--bg-image":
                "url(" + _vm.saveChannelInfo.loginBackgroundUrl + ")",
            },
          },
          [
            _c("div", { staticClass: "login-side-content" }, [
              _c("img", {
                staticClass: "login-side-logo",
                attrs: { src: this.saveChannelInfo.loginLogoUrl, alt: "" },
              }),
              _c(
                "p",
                {
                  staticClass: "login-side-font title",
                  style: {
                    fontSize: _vm.saveChannelInfo.msloganFont,
                    color: _vm.saveChannelInfo.msloganColor,
                    fontWigth: _vm.saveChannelInfo.msloganBold,
                    "text-align":
                      _vm.saveChannelInfo.msloganAlign === "0"
                        ? "left"
                        : _vm.saveChannelInfo.msloganAlign === "1"
                        ? "center"
                        : "right",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.saveChannelInfo.mainSlogan) + " ")]
              ),
              _c(
                "p",
                {
                  staticClass: "login-side-font fontStyle",
                  style: {
                    fontSize: _vm.saveChannelInfo.fsloganFont,
                    color: _vm.saveChannelInfo.fsloganColor,
                    fontWigth:
                      _vm.saveChannelInfo.fsloganBold === "1" ? 600 : 400,
                    "text-align":
                      _vm.saveChannelInfo.fsloganAlign === "0"
                        ? "left"
                        : _vm.saveChannelInfo.fsloganAlign === "1"
                        ? "center"
                        : "right",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.saveChannelInfo.deputySlogan) + " ")]
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "login-content col-md-9" }, [
          _c(
            "div",
            { staticClass: "login-navlis" },
            [
              _vm.showLogin
                ? _c("login-main", { on: { updateView: _vm.updateShowView } })
                : _c(
                    "div",
                    { staticClass: "get-pwdmain" },
                    [
                      _vm.showForget
                        ? _c("forget-pwd", {
                            on: {
                              "reset-password": _vm.showResetView,
                              login: _vm.showLoginView,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-footer" }, [
      _c("a", { attrs: { href: "" } }, [_vm._v("河洛")]),
      _c("span", [_vm._v("|")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "" } }, [_vm._v(" 服务协议 ")]),
      _c("span", [_vm._v("|")]),
      _c("a", { attrs: { href: "" } }, [_vm._v(" 权利声明 ")]),
      _c("span", [_vm._v("|")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "" } }, [_vm._v(" 版本更新 ")]),
      _c("span", [_vm._v("|")]),
      _c("a", { attrs: { href: "" } }, [_vm._v(" 帮助中心 ")]),
      _c("span", [_vm._v("|")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "" } }, [_vm._v(" 问题反馈 ")]),
      _c("span", { staticClass: "login-copyright" }, [
        _vm._v(" @2024 Future data Technology Co., LTD rights reserved "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }