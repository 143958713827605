var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scan-login" }, [
    _c("p", { staticClass: "title" }, [_vm._v("扫码登录")]),
    _c("div", { staticClass: "code" }, [
      !_vm.loginState
        ? _c("div", { ref: "codeimg", staticClass: "codeimg" })
        : _vm._e(),
    ]),
    _c("div", { staticClass: "info-txt" }, [
      _c("span", [_vm._v(_vm._s(_vm.loginMsg))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }