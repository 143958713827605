var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("img", {
        staticClass: "main-img",
        attrs: { src: this.saveChannelInfo.loginBackgroundUrl },
      }),
      _c("div", { staticClass: "logo" }, [
        _c("img", { attrs: { src: this.saveChannelInfo.loginLogoUrl } }),
      ]),
      _vm.showLogin
        ? _c("login-main", {
            attrs: { saveChannelInfo: _vm.saveChannelInfo },
            on: { updateView: _vm.updateShowView },
          })
        : _c(
            "div",
            { staticClass: "get-pwdmain" },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("重置密码")]),
              ]),
              _vm.showForget
                ? _c("forget-pwd", {
                    on: {
                      "reset-password": _vm.showResetView,
                      login: _vm.showLoginView,
                    },
                  })
                : _vm._e(),
              _vm.showReset
                ? _c("reset-pwd", {
                    attrs: { forget: _vm.forgetVal },
                    on: { login: _vm.showLoginView },
                  })
                : _vm._e(),
            ],
            1
          ),
      _c("div", { staticClass: "footer" }, [
        _c("p", [_vm._v(_vm._s(_vm.saveChannelInfo.channelName))]),
        _c("p", [
          _vm._v("@2024 Future data Technology Co., LTD rights reserved"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }