<template>
  <div class="getpin-wrap">
    <div class="step-wrap">
      <div class="step-item active">
        <i class="circular">1</i>
        <span>找回密码</span>
      </div>
      <div class="step-item">
        <i class="circular">2</i>
        <span>重置密码</span>
      </div>
    </div>
    <div class="retrieve-password">
      <el-form ref="pinForm" class="pin-form" :model="pinForm" :rules="rules">
        <el-form-item prop="tell">
          <el-input placeholder="请输入手机号" v-model="pinForm.tell"/>
        </el-form-item>
        <el-form-item prop="code">
          <el-input placeholder="请输入验证码" class="pin-inp" v-model="pinForm.code"/>
          <el-button class="getpin-btn" @click="onGetCode" :disabled="times > 0" v-loading="codeLoading">
            {{ times > 0 ? times + "s" : "获取验证码"}}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="sure-btn" @click="resetPassword" v-loading="loading">
            确定
          </el-button>
        </el-form-item>
        <a class="login-txt" @click="this.login">登录</a>
      </el-form>
    </div>
  </div>
</template>

<script>
import { loginService } from "@/api";

export default {
  name: "ForgetPwd",
  data() {
    return {
      pinForm: {
        tell: "",
        code: ""
      },
      rules: {
        tell: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: true
          }, {
            pattern: /^1\d{10}$/,
            message: "请输入正确手机号码",
            trigger: true
          }
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: true
          }, {
            min: 6,
            max: 6,
            message: "请输入6位验证码",
            trigger: true
          }
        ]
      },
      int: null,
      times: 0,
      codeLoading: false,
      loading: false
    };
  },
  methods: {
    onGetCode() {
      this.$refs.pinForm.validateField("tell", (errorMessage) => {
        if (!errorMessage) {
          this.getCode();
        }
      });
    },
    async getCode() {
      try {
        this.codeLoading = true;
        console.log(loginService);
        const res = await loginService.sendCode({
          mobileNo: this.pinForm.tell
        });
        console.log(res);
        this.times = 60;
        this.int = setInterval(() => {
          this.times--;
          if (this.times <= 0) {
            clearInterval(this.int);
            this.int = null;
          }
        }, 1000);
      } catch (e) {
        console.error(e);
      } finally {
        this.codeLoading = false;
      }
    },
    resetPassword() {
      // 传参方法
      this.$refs.pinForm.validate((valid) => {
        if (valid) {
          this.checkCode();
        }
      });
    },
    async checkCode() {
      try {
        this.loading = true;
        const res = await loginService.checkCode({
          mobileNo: this.pinForm.tell,
          validateCode: this.pinForm.code
        });
        console.log(res);
        this.$emit("reset-password", {
          mobileNo: this.pinForm.tell,
          sendMsgMobileNo: this.pinForm.tell,
          validateCode: this.pinForm.code
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    login() {
      this.$emit("login");
    }
  }
};
</script>

<style lang="scss" scoped>
.step-wrap {
  position: relative;
  display: table;
  margin: 40px auto;
  font-size: 12px;
  .circular {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #a2c9ef;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    margin-right: 10px;
  }
  .step-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 100px;
    &:last-child {
      margin-right: 0;
    }
  }
  &:after {
    position: absolute;
    left: 90px;
    right: 90px;
    top: 10px;
    content: "";
    height: 2px;
    background: #3e90fe;
  }
}
.getpin-wrap {
  .retrieve-password {
    display: table;
    margin: 0 auto;

    & /deep/ .el-input__inner,
    & /deep/ .el-input {
      height: 40px;
      width: 460px;
    }
    .pin-inp {
      width: 354px;
      margin-right: 10px;
      & /deep/ .el-input__inner,
      & /deep/ .el-input {
        width: 100%;
      }
    }
    .getpin-btn {
      background: #3e90fe;
      color: #fff;
      width: 100px;
    }
    .login-txt {
      text-align: right;
      font-size: 12px;
      color: #3e90fe;
      width: 460px;
      margin: 0 auto;
      display: block;
      cursor: pointer;
    }
  }
}
.sure-btn {
  text-align: center;
  height: 45px;
  width: 100%;
  background: #3e90fe;
  color: #fff;
  letter-spacing: 5px;
}
.active {
  .circular {
    background: #3e90fe;
  }
  span {
    color: #3e90fe;
  }
}
</style>
