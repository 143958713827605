<!--
 * @Description:
 * @Author: 周晴龙
 * @LastEditors: wuqi
 * @Date: 2019-07-19 15:06:54
 * @LastEditTime: 2021-06-18 16:53:44
 -->
 <template>
  <div class="main">
    <login-main :saveChannelInfo="saveChannelInfo"></login-main>

  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { loginService } from "@/api";
import LoginMain from "./main.vue";

export default {
  name: "Login",
  props: {
          saveChannelInfo: {
            type: Object,
            default: () => {}
          }
        },
  components: {
    LoginMain

  },
  data() {
    return {
      viewStatus: "login",
      test: "",
      forgetVal: {}
    };
  },
  created() {

  },
  computed: {

  }

};
</script>

<style lang="scss" scoped>
.main{
  height: 100%;
}
</style>
