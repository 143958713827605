var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wscn-http404-container" },
    [
      _c("en-result", {
        staticClass: "not-found",
        attrs: {
          type: "NotFound",
          title: "错误页面",
          "sub-title": "您访问的页面不存在",
        },
      }),
      _c(
        "router-link",
        { staticClass: "bullshit__return-home", attrs: { to: "/" } },
        [_vm._v(" 返回首页 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }