var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scan-login" }, [
    _c("div", { staticClass: "info-txt" }, [
      _c("span", [_vm._v(_vm._s(_vm.loginMsg))]),
    ]),
    _c("div", { staticClass: "code" }, [
      !_vm.loginState
        ? _c("div", { ref: "codeimg", staticClass: "codeimg" })
        : _vm._e(),
    ]),
    !_vm.loginState
      ? _c(
          "div",
          { staticClass: "recode", on: { click: _vm.freshCode } },
          [
            _c("en-icon", {
              staticStyle: { color: "#3cb5fc" },
              attrs: { name: "iconhuifu1", size: "16px" },
            }),
            _c("span", [_vm._v("刷新")]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }